import { ArrowLeftIcon, ReloadIcon } from "@biblioteksentralen/icons";
import { Box, Button, Flex, Grid, Heading, Spinner, Stack, Text, VStack, colors } from "@biblioteksentralen/react";
import { DefaultContainer } from "../components/ContentContainer";
import { LinksToAllSites } from "../components/LinksToAllSites";
import { LocalizedNextLink } from "../components/LocalizedNextLink";
import { ErrorPageHead } from "../components/errorPages/ErrorPageHead";
import { NotFoundFigure } from "../components/errorPages/NotFoundFigure";
import { UnknownDomainHeader } from "../components/errorPages/UnknownDomainHeader";
import { useErrorPageData } from "../components/errorPages/useErrorPageData";
import { LayoutGrid } from "../components/layout/LayoutGrid";
import { useSanityAuth } from "../components/sanityAuth/useSanityAuth";
import SplashPageFooter from "../components/splashPage/SplashPageFooter";
import { useTranslation } from "../utils/hooks/useTranslation";
import useSubdomainAliasRedirect from "../utils/useSubdomainAliasRedirect";

function NotFound() {
  const { t } = useTranslation();
  const sanityAuth = useSanityAuth();

  const { site, allSites, loading } = useErrorPageData();

  const subdomainAliasesResolved = useSubdomainAliasRedirect();

  // TODO not ideal to show a spinner on the 404-page for users not using javascript. Should consider another solution that works without javascript
  if (!subdomainAliasesResolved || loading) {
    return (
      <Grid placeContent="center" minHeight="100vh">
        <ErrorPageHead />
        <Spinner speed="1s" />
      </Grid>
    );
  }

  if (!site && !loading) {
    return (
      <Box>
        <ErrorPageHead />
        <LayoutGrid>
          <UnknownDomainHeader />
          <DefaultContainer padding={{ base: "3rem 0 7rem", md: "5rem 0 9rem" }}>
            <VStack as="main" id="main" gap="1rem" alignItems="flex-start" padding={{ base: "0 1rem 0", sm: "0 3rem" }}>
              <Heading as="h1" fontSize="2xl" marginTop="0 !important">
                {t("Oi da! Siden finnes visst ikke")}
              </Heading>
              <Text maxWidth="18rem" fontSize="md">
                {t("Det ser ut til at du leter etter nettsiden til et bibliotek.")}
                {!!allSites?.length && <> {t("Kan det være et av disse?")}</>}
              </Text>
              <LinksToAllSites sites={allSites} marginTop="1rem !important" />
            </VStack>
          </DefaultContainer>
          <SplashPageFooter
            backgroundColor={colors.grey90}
            color={colors.white}
            fontSize="sm"
            innerContainerMaxWidth="container.xl"
          />
        </LayoutGrid>
      </Box>
    );
  }

  return (
    <>
      <ErrorPageHead />
      <DefaultContainer padding="4rem 0 8rem">
        <Grid gridTemplateColumns={{ base: "1fr", sm: "1fr 1fr" }}>
          <Flex
            justifyContent={{ base: "center", md: "flex-end" }}
            alignItems="center"
            marginRight={{ base: undefined, md: "6rem" }}
            marginBottom={{ base: "2rem", md: undefined }}
          >
            <Box width={{ base: "10rem", md: "14rem" }} height={{ base: "10rem", md: "14rem" }}>
              <NotFoundFigure />
            </Box>
          </Flex>
          <VStack
            gap="1rem"
            alignItems="flex-start"
            maxWidth={{ base: "25rem", sm: "19rem" }}
            padding={{ base: "0 3rem", sm: "0 2rem 0 0" }}
          >
            <Heading as="h1" size="3xl" marginTop="0 !important">
              {t("Oisann!")}
            </Heading>
            <Heading as="h2" fontSize="2xl" marginTop="0 !important">
              {t("Her mangler det noe.")}
            </Heading>
            <Text marginTop="0 !important" fontSize="sm">
              {sanityAuth.isAuthenticated
                ? t("Hvis du nylig har publisert innhold, kan det ta et par minutter før siden dukker opp.")
                : t("Denne siden finnes ikke, eller den er så fersk at innholdet ikke er på plass enda.")}
            </Text>
            <Text marginTop="0 !important" fontSize="sm">
              {"("}
              {t("Feilkode 404")}
              {")"}
            </Text>
            <Stack gap="0.25rem" marginTop="0 !important" flexDirection={{ base: "column", md: "row" }}>
              {sanityAuth.isAuthenticated && (
                <Button
                  onClick={() => window.location.reload()}
                  size="md"
                  leftIcon={<ReloadIcon width="1.25rem" height="1.25rem" />}
                  marginTop={{ base: "0.5rem !important", md: "1rem !important" }}
                >
                  {t("Prøv på nytt")}
                </Button>
              )}
              <Button
                as={LocalizedNextLink}
                href="/"
                size="md"
                leftIcon={<ArrowLeftIcon />}
                marginTop={{
                  base: sanityAuth.isAuthenticated ? "1rem !important" : "0.5rem !important",
                  md: "1rem !important",
                }}
                variant={sanityAuth.isAuthenticated ? "secondary" : undefined}
              >
                {t("Gå til forsiden")}
              </Button>
            </Stack>
          </VStack>
        </Grid>
      </DefaultContainer>
    </>
  );
}

export default NotFound;
