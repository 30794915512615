import { filters, getSiteUrl } from "@libry-content/common";
import { LanguageCode } from "@libry-content/localization";
import { Site } from "@libry-content/types";
import { useRouter } from "next/navigation";
import punycode from "punycode/"; // trailing slash due to https://github.com/mathiasbynens/punycode.js/issues/79#issuecomment-373773560
import { useEffect, useState } from "react";
import { logEvent } from "./analytics/Plausible";
import { sanityClient } from "./sanity/client";

// To avoid extra requests in middleware we perform the redirect client side after reaching 404.

type SubdomainAliases = Pick<Site, "_id" | "subdomain" | "subdomainAliases" | "defaultLanguage">;

// Aliases not implemented for customDomain
const subdomainQuery = `*[ _type == "site" && ${filters.isNotDraft} && !defined(customDomain) ]{ _id, subdomain, subdomainAliases, defaultLanguage }`;

type SubdomainAliasState = "initial" | "fetching" | "resolved";

type SubdomainMap = Record<string, { _id: string; subdomain: string; defaultLanguage?: LanguageCode }>;

const emptyMap: SubdomainMap = {};

const getSubdomain = (site: string | string[] | undefined): string | undefined =>
  typeof site === "string" ? site.split(".")[0] : undefined;

const reduceAliases = (
  _id: string,
  subdomain: string,
  subdomainAliases: string[] | undefined,
  defaultLanguage?: LanguageCode
) =>
  (subdomainAliases ?? []).reduce((acc, alias) => ({ ...acc, [alias]: { _id, subdomain, defaultLanguage } }), emptyMap);

// On most pages we can get the site using next/router with useRouter().query.site, but that does not work
// on 404 due to bug: https://github.com/vercel/next.js/issues/35990. The bug is fixed in later versions
// of next 13. Another option would be to use the built in error but we want to use our own page:
// https://nextjs.org/docs/advanced-features/custom-error-page#reusing-the-built-in-error-page
export const getHostName = () => (typeof window !== "undefined" ? window.location.hostname : undefined);

const useSubdomainAliasRedirect = () => {
  const { replace } = useRouter();
  const [subdomainAliasState, setSubdomainAliasState] = useState<SubdomainAliasState>("initial");
  const site = getHostName();

  useEffect(() => {
    const subdomain = punycode.toUnicode(getSubdomain(site) ?? "");
    if (!subdomain || subdomainAliasState !== "initial") return;

    setSubdomainAliasState("fetching");

    sanityClient
      .fetch<SubdomainAliases[]>(subdomainQuery)
      .then((result) => {
        const subdomainAliasMap = result.reduce(
          (acc, { _id, subdomain, subdomainAliases, defaultLanguage }) =>
            subdomain ? { ...acc, ...reduceAliases(_id, subdomain, subdomainAliases, defaultLanguage) } : acc,
          emptyMap
        );

        if (subdomain in subdomainAliasMap) {
          const { _id: redirectId, subdomain: redirectSubdomain, defaultLanguage } = subdomainAliasMap[subdomain]!;
          const redirectUrl = `${getSiteUrl({
            _id: redirectId,
            subdomain: redirectSubdomain,
            defaultLanguage,
          })}`;

          console.info(`subdomain alias redirect: ${site} ➡️ ${redirectUrl}`);
          logEvent("Redirected from subdomain alias", { alias: site, redirect: redirectUrl });
          replace(redirectUrl);

          // Don't setSubdomainAliasState("resolved") to avoid flash of 404 page before redirect
        } else {
          setSubdomainAliasState("resolved");
        }
      })
      .catch((err) => {
        console.error(err);
        setSubdomainAliasState("resolved");
      });
  }, [replace, site, subdomainAliasState]);

  return subdomainAliasState === "resolved";
};

export default useSubdomainAliasRedirect;
